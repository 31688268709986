@font-face {
  font-family: "Vogue";
  src: url("/assets/fonts/Vogue.ttf") format("truetype");
}

@font-face {
  font-family: "Exo 2";
  src: url("/assets/fonts/Exo2-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Exo 2";
  font-weight: bold;
  src: url("/assets/fonts/Exo2-Bold.ttf") format("truetype");
}

:root,
* {
  --ion-font-family: "Exo 2", sans-serif;
}
