.text-bold {
  font-weight: bold;
}

ion-title {
  color: var(--ion-color-secondary);
}

ion-button {
  font-weight: bold;
}

.MuiInputLabel-formControl {
  left: 16px !important;
}

input,
label {
  font-family: "Exo 2", sans-serif !important;
  color: black !important;
}

label.Mui-focused {
  color: #d32f2f !important;
}

@supports (padding: max(0px)) {
  .padding-notch {
    padding-top: max(12px, env(safe-area-inset-top));
  }
}

ion-avatar {
  background-color: var(--ion-color-secondary);
  color: white;
  display: grid;
  place-items: center;
}

ion-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ion-modal {
  overflowY: auto;
}